import { createSlice } from '@reduxjs/toolkit';
import chatsOperations from './chats-operations';
import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState: {
    allExpertsChats: [],
    allCustomersChats: [],
    oneExpertChat: {},
    unreadCountChat: null,
    oneCustomerChat: {},
    isLoading: false,
    error: null,
    total: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    chatsClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(chatsOperations.fetchAllChatsExpert.pending, handlePending)
      .addCase(
        chatsOperations.fetchAllChatsExpert.fulfilled,
        (state, action) => {
          state.chatsExpertList = action.payload.data;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.fetchAllChatsExpert.rejected, handleRejected)

      .addCase(chatsOperations.fetchOneExpertChat.pending, handlePending)
      .addCase(
        chatsOperations.fetchOneExpertChat.fulfilled,
        (state, action) => {
          // console.log('Payload:', action.payload.data.messages);
          state.oneExpertChat = action.payload.data;
          // state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.fetchOneExpertChat.rejected, handleRejected)

      .addCase(chatsOperations.sendMessageExpert.pending, handlePending)
      .addCase(chatsOperations.sendMessageExpert.fulfilled, (state, action) => {
        // state.allExpertsChats.push(action.payload);
        state.oneExpertChat?.messages.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(chatsOperations.sendMessageExpert.rejected, handleRejected)

      .addCase(chatsOperations.deleteExpertChat.pending, handlePending)
      .addCase(chatsOperations.deleteExpertChat.fulfilled, (state, action) => {
        const index = state.chatsExpertList.findIndex(
          item => item._id === action.meta.arg
        );
        if (index !== -1) {
          state.chatsExpertList.splice(index, 1);
        }
        state.oneExpertChat = {};
        state.isLoading = false;
        state.error = false;
      })
      .addCase(chatsOperations.deleteExpertChat.rejected, handleRejected)

      .addCase(chatsOperations.removeExpertMessage.pending, handlePending)
      .addCase(
        chatsOperations.removeExpertMessage.fulfilled,
        (state, action) => {
          const index = state.oneExpertChat?.messages.findIndex(
            item => item._id === action.meta.arg
          );
          state.oneExpertChat?.messages.splice(index, 1);
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(chatsOperations.removeExpertMessage.rejected, handleRejected)

      .addCase(chatsOperations.editExpertMassage.pending, handlePending)
      .addCase(chatsOperations.editExpertMassage.fulfilled, (state, action) => {
        // console.log(action.payload, 'action.payload');
        const messageIndex = state.oneExpertChat?.messages.findIndex(
          message => message._id === action.payload._id
        );

        if (messageIndex !== -1) {
          state.oneExpertChat.messages[messageIndex].content =
            action.payload.content;
        }

        state.isLoading = false;
        state.error = null;
      })
      .addCase(chatsOperations.editExpertMassage.rejected, handleRejected)

      .addCase(chatsOperations.markMessagesAsReadExpert.pending, handlePending)
      .addCase(
        chatsOperations.markMessagesAsReadExpert.fulfilled,
        (state, action) => {
          action.payload.messages.forEach(updatedMessage => {
            const index = state.oneExpertChat.messages.findIndex(
              msg => msg._id === updatedMessage._id
            );
            if (index !== -1) {
              state.oneExpertChat.messages[index] = {
                ...state.oneExpertChat.messages[index],
                ...updatedMessage,
              };
            }
          });
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        chatsOperations.markMessagesAsReadExpert.rejected,
        handleRejected
      )
      .addCase(chatsOperations.unreadCountChatsExpert.pending, handlePending)
      .addCase(
        chatsOperations.unreadCountChatsExpert.fulfilled,
        (state, action) => {
          // console.log(state);
          console.log(action.payload.data.unreadCount);
          // console.log('Payload:', action.payload.data.messages);
          state.unreadCountChat = action.payload.data.unreadCount;
          // state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.unreadCountChatsExpert.rejected, handleRejected)

      // Customer
      .addCase(chatsOperations.fetchAllCustomersChats.pending, handlePending)
      .addCase(
        chatsOperations.fetchAllCustomersChats.fulfilled,
        (state, action) => {
          state.allCustomersChats = action.payload.data;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.fetchAllCustomersChats.rejected, handleRejected)

      .addCase(chatsOperations.fetchOneCustomerChat.pending, handlePending)
      .addCase(
        chatsOperations.fetchOneCustomerChat.fulfilled,
        (state, action) => {
          // console.log('Payload:', action.payload.data.messages);
          state.oneCustomerChat = action.payload.data;
          // state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.fetchOneCustomerChat.rejected, handleRejected)

      .addCase(chatsOperations.sendMessageCustomer.pending, handlePending)
      .addCase(
        chatsOperations.sendMessageCustomer.fulfilled,
        (state, action) => {
          state.oneCustomerChat?.messages.push(action.payload);
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.sendMessageCustomer.rejected, handleRejected);
  },
});

export const { setError, chatsClearError } = chatsSlice.actions;

export default chatsSlice.reducer;
